import React, { useEffect, useState } from "react";
// @material-ui/core components
import { Dialog, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import { toast } from "react-toastify"
import Switch from "@mui/material/Switch";
import { Autocomplete, TextField, typography } from "@mui/material";
import { EditLabAPI } from "../../config/api.js";
import { useSelector } from "react-redux";


const styles = {
    formControl: {
        paddingBottom: "10px",
        margin: "27px 0 0 0",
        position: "relative",
        verticalAlign: "unset"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

const sides = ['B', 'L', 'R'];

export default function LabEdit(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { open, close, fetchData, lab } = props;

    console.log(lab, "My edit lab")
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [labId, setLabId] = useState("")
    const [labName, setLabName] = useState('');
    const [labEmail, setLabEmail] = useState('');
    const [labPhoneNumber, setLabPhoneNumber] = useState('');
    const [status, setStatus] = useState('');
    const [selectedStatus, setSelectedStatus] = useState("Active");

    const [error, setError] = useState("");

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{10}$/; // Adjust the regex for your requirements (e.g., country-specific format)
    if (!phoneRegex.test(phone)) {
      setError("Please enter a valid 10-digit phone number.");
    } else {
      setError("");
    }
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    setLabPhoneNumber(value);
    validatePhoneNumber(value);
  };

    useEffect(() => {
        setLabName(lab?.labName)
        setLabEmail(lab?.labEmail)
        setLabPhoneNumber(lab?.labPhoneNumber)
        setSelectedStatus(lab?.status)
        setLabId(lab?._id)
    }, [open])


    let userInfo = useSelector((state) => state.userReducer.userInfo);


    async function editLab() {
        try {
            let lab = {
                labName,
                labEmail,
                labPhoneNumber,
                status: selectedStatus,
            };
            console.log("lab going to api", lab)
            if (labName === "") {
                toast.error("Please fill all the fields", {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }

            // return
            let res = await axios.patch(EditLabAPI(labId), lab);
            console.log(res)
            toast.success('Successfully Edited!', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            close();
            fetchData()
            setLabName("")
            setLabEmail("")
        } catch (e) {

        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            aria-labelledby="responsive-dialog-title">
            <GridContainer>
                <GridItem xs={12} sm={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Edit Lab Master</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={6}>
                                    <CustomInput
                                        labelText="Lab Name*"
                                        id="labName"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: labName,
                                            onChange: (event) => setLabName(event.target.value)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <CustomInput
                                        labelText="Lab Email*"
                                        id="labEmail"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: labEmail,
                                            onChange: (event) => setLabEmail(event.target.value)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                        <CustomInput
                                            labelText="Lab Phone Number*"
                                            id="labPhoneNumber"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: labPhoneNumber,
                                                onChange: handlePhoneNumberChange,
                                            }}
                                        />
                                        {error && (
                                            <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                                                {error}
                                            </div>
                                        )}
                                    </GridItem>
                                <GridItem xs={12} style={{ marginTop: 26 }}>
                                    <Autocomplete
                                        id="type"
                                        className={classes.formControl}
                                        options={["Inactive", "Active"]}
                                        value={selectedStatus}
                                        onChange={(event, value) => {
                                            setSelectedStatus(value);
                                        }}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Status" />
                                        )}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter style={{ justifyContent: 'center' }}>
                            <Button color="primary" onClick={() => editLab()}>Edit Lab Moster</Button>
                        </CardFooter>
                    </Card>
                </GridItem>


            </GridContainer>
        </Dialog>
    );
}
