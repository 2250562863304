import React, { useEffect, useState } from "react";
import _, { set } from "lodash";
import StatePicker from "countrycitystatejson";
// @material-ui/core components
import {
  Box,
  Dialog,
  Fab,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import axios from "axios";
import { GetAllProductMastersAPI, UpdateAccountAPI } from "../../config/api";
import Check from "@material-ui/icons/Check";
import styles from "../../../src/assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import { toast } from "react-toastify";

let s = _.merge(styles, {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
});

const useStyles = makeStyles(styles);

function getSteps() {
  return ["Account Details", "Account Users", "Discount"];
}

const columns = [
  { id: "product", label: "Product" },
  { id: "discountPercent", label: "Discount Percent" },
  { id: "discountedPrice", label: "Discount Amount" },
];

const accountUsersColumns = [
  { id: "name", label: "Name" },
  { id: "mobile", label: "Mobile" },
  { id: "role", label: "Role" },
  { id: "permissions", label: "Permissions" },
];
// const products = ['Product 1', 'Product 2', 'Product 3', 'Product 4', 'Product 5'];

export default function AccountEdit(props) {
  const classes = useStyles();
  const theme = useTheme();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const { open, close, item, fetchData } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  let { } = item;

  const [accountCode, setAccountCode] = useState(item.accountCode);
  const [partyType, setPartyType] = useState(item.partyType);
  const [name, setName] = useState(item.name);
  const [address, setAddress] = useState(item.address);
  const [city, setCity] = useState(item.city);
  const [state, setState] = useState(item.state);
  const [pin, setPin] = useState(item.pin);

  const [mobile, setMobile] = useState(item.mobile);
  const [email, setEmail] = useState(item.email);
  const [shippingAddress, setShippingAddress] = useState(
    item?.shippingAddress
      ? { allAddress: item.shippingAddress }
      : {
        allAddress: [
          {
            fullname: "",
            address: "",
            stateAddress: "",
            city: "",
            pincode: "",
          },
        ],
      }
  );
  const [openBalance, setOpenBalance] = useState(item.openBalance);
  const [currentBalance, setCurrentBalance] = useState(item.currentBalance);
  const [lastYearBalance, setLastYearBalance] = useState(item.lastYearBalance);
  const [challanType, setChallanType] = useState(item.challanType);
  const [landline, setLandline] = useState(item?.landline ? item.landline : "");
  const [showRunningBalance, setShowRunningBalance] = useState(
    item?.showRunningBalance ? item.showRunningBalance : false
  );
  const [isEligibleForIgst, setIsELigibleForIgst] = useState(
    item?.isEligibleForIgst ? item.isEligibleForIgst : false
  );
  
  const [showPrice, setShowPrice] = useState(
    item?.showPrice ? item.showPrice : false
  );
  const [whatsappNumber, setWhatsappNumber] = useState(
    item?.whatsappNumber ? item.whatsappNumber : ""
  );

  const [discounts, setDiscounts] = useState(item.discounts);
  const [cashPreference, setCashPreference] = useState(
    item?.cashPreference ? item.cashPreference : false
  );
  const [thresholdBalance, setThresholdBalance] = useState(
    item?.thresholdBalance ? item.thresholdBalance : ""
  );
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState("");
  const [discountPercent, setDiscountPercent] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState(StatePicker.getStatesByShort("IN"));
  
  const [runningChallanBalance, setrunningChallanBalance] = useState(
    item?.runningChallanBalance ? item.runningChallanBalance : ""
  );
  
  const [openingChallanBalance, setOpeningChallanBalance] = useState(
    item?.openingChallanBalance ? item.openingChallanBalance : ""
  );
  
  const [runningBillBalance, setrunningBillBalance] = useState(
    item?.runningBillBalance ? item.runningBillBalance : ""
  );
  
  const [openingBillBalance, setOpeningBillBalance] = useState(
    item?.openingBillBalance ? item.openingBillBalance : ""
  );

  const [gstNumber, setGstNumber] = useState(
    item?.gstNumber ? item.gstNumber : ""
  );

  const [employeeName, setEmployeeName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeePassword, setEmployeePassword] = useState("");
  const [employeeMobile, setEmployeeMobile] = useState("");
  const [employeeAddress, setEmployeeAddress] = useState("");
  const [employeeRole, setEmployeeRole] = useState(false);

  const [viewPurchase, setViewPurchase] = useState(false);
  const [viewAccounts, setViewAccounts] = useState(false);
  const [viewAccountsVerification, setViewAccountsVerification] =
    useState(false);
  const [viewDiscounts, setViewDiscounts] = useState(false);
  const [viewOrders, setViewOrders] = useState(false);
  const [viewOrdersApp, setViewOrdersApp] = useState(false);
  const [viewOrderReturn, setViewOrderReturn] = useState(false);
  const [viewTransaction, setViewTransaction] = useState(false);
  const [viewOrderStatus, setViewOrderStatus] = useState(false);
  const [viewPrintAd, setViewPrintAd] = useState(false);
  const [viewBankList, setViewBankList] = useState(false);
  const [viewPaymentList, setViewPaymentList] = useState(false);
  const [viewPurchaseBarcode, setViewPurchaseBarcode] = useState(false);
  const [viewProductBarcode, setViewProductBarcode] = useState(false);
  const [viewOrderPrintPage, setViewOrderPrintPage] = useState(false);
  const [viewBrandsList, setViewBrandsList] = useState(false);
  const [viewOfferList, setViewOfferList] = useState(false);
  const [viewTrendingList, setViewTrendingList] = useState(false);
  const [viewBrochureList, setViewBrochureList] = useState(false);
  const [viewPriceList, setViewPriceList] = useState(false);
  const [viewMaster, setViewMaster] = useState(false);
  const [viewCategoryList, setViewCategoryList] = useState(false);
  const [viewAccounting, setViewAccounting] = useState(false);
  const [viewDashboard, setViewDashboard] = useState(false);
  const [viewSystemUsers, setViewSystemUsers] = useState(false);
  const [viewAccountUsers, setViewAccountUsers] = useState(false);

  const [selectAll, setSelectAll] = useState(false);

  const [employees, setEmployees] = useState(item.users);

  useEffect(() => {
    _getProducts();
  }, []);

  useEffect(() => {
    let cities = StatePicker.getCities("IN", state);
    setCities(cities);
  }, [state]);

  async function _getProducts() {
    try {
      let res = await axios.get(GetAllProductMastersAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setProducts(res.data.productMasters);
    } catch (err) {
      // toast.error({ err }, {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _onSave() {
    try {
      let account = {
        accountCode,
        partyType,
        name,
        address,
        city,
        state,
        pin,
        landline,
        mobile,
        email,
        cashPreference,
        thresholdBalance,
        shippingAddress: shippingAddress.allAddress,
        openBalance,
        currentBalance,
        lastYearBalance,
        discounts,
        whatsappNumber,
        challanType,
        showRunningBalance,
        gstNumber,
        users: employees,
        openingChallanBalance,
        openingBillBalance,
        isEligibleForIgst,
        showPrice,
      };

      let res = await axios.patch(UpdateAccountAPI(item._id), account, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success("Account Edited Successfully", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(employees, 'this is empplouyee')
      close();
      fetchData();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  function addShippingAddressInput() {
    setShippingAddress({
      allAddress: [
        ...shippingAddress.allAddress,
        { fullname: "", address: "", stateAddress: "", city: "", pincode: "" },
      ],
    });
  }

  function removeShippingAddressInput(index) {
    let copy = _.clone(shippingAddress.allAddress);
    copy.splice(index, 1);
    setShippingAddress({
      allAddress: copy,
    });
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      // fullScreen={fullScreen}
      open={open}
      fullScreen
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <div style={{ padding: 40, backgroundColor: "#eeeeee" }}>
        <Card>
          <CardHeader color="primary">
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <h4 className={classes.cardTitleWhite}>Account Detail</h4>
              <Button color={"transparent"} onClick={close}>
                Close
              </Button>
            </Box>
          </CardHeader>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? (
            <>
              <CardBody>
                <GridContainer spacing={4}>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="A/C Code"
                      id="accountCode"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: accountCode,
                        onChange: (event) => setAccountCode(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Autocomplete
                      id="partyType"
                      className={classes.formControl}
                      options={["sale", "purchase"]}
                      value={partyType}
                      onChange={(event, value) => {
                        setPartyType(value);
                      }}
                      getOptionLabel={(option) => (option ? option : "")}
                      renderInput={(params) => (
                        <TextField {...params} label="Party Type" />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Name"
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: name,
                        onChange: (event) => setName(event.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Company Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: email,
                        onChange: (event) => setEmail(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Gst Number"
                      id="gstNumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: gstNumber,
                        onChange: (event) => setGstNumber(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Address"
                      id="address"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        value: address,
                        onChange: (event) => setAddress(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Autocomplete
                      id="state"
                      className={classes.formControl}
                      options={states}
                      value={state}
                      onChange={(event, value) => {
                        setState(value);
                      }}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} label="Select State" />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Autocomplete
                      id="city"
                      className={classes.formControl}
                      options={cities}
                      value={city}
                      onChange={(event, value) => {
                        setCity(value);
                      }}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} label="Select City" />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Pincode"
                      id="pin"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: pin,
                        onChange: (event) => setPin(event.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Landline"
                      id="landline"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: landline,
                        onChange: (event) => setLandline(event.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Mobile"
                      id="mobile"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: mobile,
                        onChange: (event) => setMobile(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Whatsapp Number"
                      id="mobile"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: whatsappNumber,
                        onChange: (event) =>
                          setWhatsappNumber(event.target.value),
                      }}
                    />
                  </GridItem>


                  {/* <GridItem xs={6} sm={6} md={2}>
                    <CustomInput
                      labelText="Threshold Balance"
                      id="thresholdBalance"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: thresholdBalance,
                        onChange: (event) =>
                          setThresholdBalance(event.target.value),
                      }}
                    />
                  </GridItem> */}

                  {/* <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Open Balance"
                      id="openBalance"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: openBalance,
                        onChange: (event) => setOpenBalance(event.target.value),
                      }}
                    />
                  </GridItem> */}
                  {/* <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Current Balance"
                      id="currentBalance"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: currentBalance,
                        onChange: (event) =>
                          setCurrentBalance(event.target.value),
                      }}
                    />
                  </GridItem> */}
                  {/* <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Last Year Balance"
                      id="lastYearBalance"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: lastYearBalance,
                        onChange: (event) =>
                          setLastYearBalance(event.target.value),
                      }}
                    />
                  </GridItem> */}

                  {/* <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Opening Balance"
                      id="openingBalance"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: _.round(openingBalance, 2),
                        onChange: (event) =>
                          setOpeningBalance(event.target.value),
                      }}
                    />
                  </GridItem> 
                  <GridItem xs={12} sm={12} md={2}>
                    <Autocomplete
                      id="challanType"
                      className={classes.formControl}
                      options={["challan", "A6", "Thermal"]}
                      value={challanType}
                      onChange={(event, value) => {
                        setChallanType(value);
                      }}
                      getOptionLabel={(option) => (option ? option : "")}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Challan Type" />
                      )}
                    />
                  </GridItem>*/}


                  {/* 

// openingChallanBalance,
    // runningChallanBalance
// runningChallanBalance
// openingBillBalance,
    // runningBillBalance
// runningBillBalance
// openingPurchaseBalance
runningPurchaseBalance 

*/}

                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Challan Opening Balance"
                      id="challanBalance"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: _.round(openingChallanBalance, 2),
                        onChange: (event) =>
                          setOpeningChallanBalance(event.target.value),
                      }}
                    />
                  </GridItem>
                  
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Challan Running Balance"
                      id="challanBalance"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      disabled={true}
                      inputProps={{
                        value: _.round(runningChallanBalance, 2),
                        // onChange: (event) =>
                          // setrunningChallanBalance(event.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Bill Opening Balance"
                      id="billBalance"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: _.round(openingBillBalance, 2),
                        onChange: (event) =>
                          setOpeningBillBalance(event.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Bill Running Balance"
                      id="billBalance"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      disabled={true}
                      inputProps={{
                        value: _.round(runningBillBalance, 2),
                        // onChange: (event) =>
                          // setrunningBillBalance(event.target.value),
                      }}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={12} md={12} style={{ alignSelf: "end", flexDirection: 'row' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onChange={(e) => {
                            setShowRunningBalance(e.target.checked);
                          }}
                          value={showRunningBalance}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Show Running Balance"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onChange={(e) => {
                            setIsELigibleForIgst(e.target.checked);
                          }}
                          value={cashPreference}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Is Eligible for Igst"
                    />
                    
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onChange={(e) => {
                            setShowPrice(e.target.checked);
                          }}
                          value={showPrice}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Show Price in Printables"
                    />
                  </GridItem>
                

                  {/* <GridItem xs={6} sm={6} md={2} style={{ alignSelf: "end" }}>
                    
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onChange={(e) => {
                            setIsELigibleForIgst(e.target.checked);
                          }}
                          // value={cashPreference}
                          checked={isEligibleForIgst}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Is Eligible for Igst"
                    />
                  </GridItem> */}


                  {shippingAddress.allAddress.map((i, index) => (
                    <>
                      <>
                        <GridContainer
                          spacing={2}
                          style={{ margin: 0, width: "90%" }}
                        >
                          <GridItem xs={12} sm={12} md={2}>
                            <CustomInput
                              labelText="Full Name"
                              id="address"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                multiline: true,
                                value: i.fullname,
                                onChange: (event) =>
                                  setShippingAddress({
                                    allAddress: [
                                      ...shippingAddress.allAddress.splice(
                                        0,
                                        index
                                      ),
                                      {
                                        ...shippingAddress.allAddress[index],
                                        fullname: event.target.value,
                                      },
                                      ...shippingAddress.allAddress.splice(
                                        index + 1
                                      ),
                                    ],
                                  }),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={2}>
                            <CustomInput
                              error={i.address >= 100}
                              labelText="Shipping Address"
                              id="shippingAddress"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                multiline: true,
                                value: i.address,
                                min: 0,
                                // max: 100,
                                // maxLength: 100,
                                onChange: (event) => {
                                  // if (event.target.value.length <= 100) 
                                  {
                                    setShippingAddress({
                                      allAddress: [
                                        ...shippingAddress.allAddress.splice(
                                          0,
                                          index
                                        ),
                                        {
                                          ...shippingAddress.allAddress[index],
                                          address: event.target.value,
                                        },
                                        ...shippingAddress.allAddress.splice(
                                          index + 1
                                        ),
                                      ],
                                    });
                                  }
                                },
                              }}
                            />
                            {/* {i.address.length == 100 ||
                              (i.address.length == 0 && (
                                <FormHelperText id="shippingAddress">
                                  Only 100 Characeter Allowed
                                </FormHelperText>
                              ))}
                            {i.address.length > 0 && (
                              <FormHelperText id="shippingAddress">
                                {100 - i.address.length} Characeters Left
                              </FormHelperText>
                            )} */}
                          </GridItem>

                          <GridItem xs={12} sm={12} md={2}>
                            <Autocomplete
                              id="state"
                              className={classes.formControl}
                              options={states}
                              value={i.stateAddress}
                              onChange={(event, value) => {
                                setShippingAddress({
                                  allAddress: [
                                    ...shippingAddress.allAddress.splice(
                                      0,
                                      index
                                    ),
                                    {
                                      ...shippingAddress.allAddress[index],
                                      stateAddress: value,
                                    },
                                    ...shippingAddress.allAddress.splice(
                                      index + 1
                                    ),
                                  ],
                                });

                                let cities = StatePicker.getCities("IN", value);
                                setCities(cities);
                              }}
                              getOptionLabel={(option) =>
                                option ? option : ""
                              }
                              renderInput={(params) => (
                                <TextField {...params} label="Select State" />
                              )}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={2}>
                            <Autocomplete
                              id="city"
                              className={classes.formControl}
                              options={cities}
                              value={i.city}
                              onChange={(event, value) => {
                                setShippingAddress({
                                  allAddress: [
                                    ...shippingAddress.allAddress.splice(
                                      0,
                                      index
                                    ),
                                    {
                                      ...shippingAddress.allAddress[index],
                                      city: value,
                                    },
                                    ...shippingAddress.allAddress.splice(
                                      index + 1
                                    ),
                                  ],
                                });
                              }}
                              getOptionLabel={(option) =>
                                option ? option : ""
                              }
                              renderInput={(params) => (
                                <TextField {...params} label="Select City" />
                              )}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={2}>
                            <CustomInput
                              labelText="Pincode"
                              id="pin"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: i.pin,
                                onChange: (event) =>
                                  setShippingAddress({
                                    allAddress: [
                                      ...shippingAddress.allAddress.splice(
                                        0,
                                        index
                                      ),
                                      {
                                        ...shippingAddress.allAddress[index],
                                        pincode: event.target.value,
                                      },
                                      ...shippingAddress.allAddress.splice(
                                        index + 1
                                      ),
                                    ],
                                  }),
                              }}
                            />
                          </GridItem>
                          {index ? (
                            <GridItem
                              xs={12}
                              sm={12}
                              md={1}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                color="secondary"
                                onClick={() =>
                                  removeShippingAddressInput(index)
                                }
                              >
                                Remove
                              </Button>
                            </GridItem>
                          ) : null}
                        </GridContainer>
                      </>
                    </>
                  ))}
                  <GridItem xs={12} sm={12} md={1}>
                    <Button onClick={addShippingAddressInput}>Add</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </>
          ) : null}
          {activeStep === 1 ? (
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={2}>
                  <CustomInput
                    labelText="Employee Name"
                    id="EmployeeName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: employeeName,
                      onChange: (event) => setEmployeeName(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={2}>
                  <CustomInput
                    labelText="Employee Email"
                    id="EmployeeEmail"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: employeeEmail,
                      onChange: (event) => setEmployeeEmail(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={2}>
                  <CustomInput
                    labelText="Employee Password"
                    id="EmployeePassword"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: employeePassword,
                      onChange: (event) =>
                        setEmployeePassword(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={2}>
                  <CustomInput
                    labelText="Employee Mobile"
                    id="EmployeeMobile"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: employeeMobile,
                      onChange: (event) =>
                        setEmployeeMobile(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Employee Address"
                    id="EmployeeAddress"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: employeeAddress,
                      onChange: (event) =>
                        setEmployeeAddress(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={2}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <FormControlLabel
                    label="Add As Admin"
                    control={
                      <Checkbox
                        checked={employeeRole}
                        onChange={(e) => setEmployeeRole(e.target.checked)}
                      />
                    }
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    label="Select All"
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={(e) => {
                          setSelectAll(e.target.checked);
                          setViewDashboard(e.target.checked);
                          setViewSystemUsers(e.target.checked);
                          setViewAccountUsers(e.target.checked);

                          setViewPurchase(e.target.checked);
                          setViewAccounts(e.target.checked);
                          setViewAccountsVerification(e.target.checked);
                          setViewDiscounts(e.target.checked);
                          setViewOrders(e.target.checked);
                          setViewOrdersApp(e.target.checked);
                          setViewOrderReturn(e.target.checked);
                          setViewMaster(e.target.checked);
                          setViewTransaction(e.target.checked);
                          setViewOrderStatus(e.target.checked);
                          setViewAccounting(e.target.checked);
                          setViewPrintAd(e.target.checked);
                          setViewBankList(e.target.checked);
                          setViewPaymentList(e.target.checked);
                          setViewPurchaseBarcode(e.target.checked);
                          setViewProductBarcode(e.target.checked);
                          setViewOrderPrintPage(e.target.checked);
                          setViewBrandsList(e.target.checked);
                          setViewCategoryList(e.target.checked);
                          setViewOfferList(e.target.checked);
                          setViewTrendingList(e.target.checked);
                          setViewBrochureList(e.target.checked);
                          setViewPriceList(e.target.checked);
                        }}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Dashboard"
                    control={
                      <Checkbox
                        checked={viewDashboard}
                        onChange={(e) => setViewDashboard(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View System Users"
                    control={
                      <Checkbox
                        checked={viewSystemUsers}
                        onChange={(e) => setViewSystemUsers(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Account Users"
                    control={
                      <Checkbox
                        checked={viewAccountUsers}
                        onChange={(e) => setViewAccountUsers(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Purchase"
                    control={
                      <Checkbox
                        checked={viewPurchase}
                        onChange={(e) => setViewPurchase(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Accounts"
                    control={
                      <Checkbox
                        checked={viewAccounts}
                        onChange={(e) => setViewAccounts(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View AccountsVerification"
                    control={
                      <Checkbox
                        checked={viewAccountsVerification}
                        onChange={(e) =>
                          setViewAccountsVerification(e.target.checked)
                        }
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Discounts"
                    control={
                      <Checkbox
                        checked={viewDiscounts}
                        onChange={(e) => setViewDiscounts(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Orders"
                    control={
                      <Checkbox
                        checked={viewOrders}
                        onChange={(e) => setViewOrders(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View OrdersApp"
                    control={
                      <Checkbox
                        checked={viewOrdersApp}
                        onChange={(e) => setViewOrdersApp(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Orders Return"
                    control={
                      <Checkbox
                        checked={viewOrderReturn}
                        onChange={(e) => setViewOrderReturn(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Master"
                    control={
                      <Checkbox
                        checked={viewMaster}
                        onChange={(e) => setViewMaster(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Transaction"
                    control={
                      <Checkbox
                        checked={viewTransaction}
                        onChange={(e) => setViewTransaction(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View OrderStatus"
                    control={
                      <Checkbox
                        checked={viewOrderStatus}
                        onChange={(e) => setViewOrderStatus(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Accounting"
                    control={
                      <Checkbox
                        checked={viewAccounting}
                        onChange={(e) => setViewAccounting(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View PrintAd"
                    control={
                      <Checkbox
                        checked={viewPrintAd}
                        onChange={(e) => setViewPrintAd(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View BankList"
                    control={
                      <Checkbox
                        checked={viewBankList}
                        onChange={(e) => setViewBankList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View PaymentList"
                    control={
                      <Checkbox
                        checked={viewPaymentList}
                        onChange={(e) => setViewPaymentList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Purchase Barcode"
                    control={
                      <Checkbox
                        checked={viewPurchaseBarcode}
                        onChange={(e) =>
                          setViewPurchaseBarcode(e.target.checked)
                        }
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Product Barcode"
                    control={
                      <Checkbox
                        checked={viewProductBarcode}
                        onChange={(e) =>
                          setViewProductBarcode(e.target.checked)
                        }
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Order Print Page "
                    control={
                      <Checkbox
                        checked={viewOrderPrintPage}
                        onChange={(e) =>
                          setViewOrderPrintPage(e.target.checked)
                        }
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View BrandsList"
                    control={
                      <Checkbox
                        checked={viewBrandsList}
                        onChange={(e) => setViewBrandsList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View CategoryList"
                    control={
                      <Checkbox
                        checked={viewCategoryList}
                        onChange={(e) => setViewCategoryList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View OfferList"
                    control={
                      <Checkbox
                        checked={viewOfferList}
                        onChange={(e) => setViewOfferList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View TrendingList"
                    control={
                      <Checkbox
                        checked={viewTrendingList}
                        onChange={(e) => setViewTrendingList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View BrochureList"
                    control={
                      <Checkbox
                        checked={viewBrochureList}
                        onChange={(e) => setViewBrochureList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View PriceList"
                    control={
                      <Checkbox
                        checked={viewPriceList}
                        onChange={(e) => setViewPriceList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Fab
                    size={"small"}
                    color="primary"
                    aria-label="add"
                    onClick={() => {
                      if (employeeName === "") {
                        return alert("Please Enter Employee Name");
                      }
                      if (employeeMobile === "") {
                        return alert("Please Enter Employee Mobile");
                      }
                      if (employeeEmail === "") {
                        return alert("Please Enter Employee Email");
                      }
                      if (employeePassword === "") {
                        return alert("Please Enter Employee Password");
                      }
                      let data = {
                        name: employeeName,
                        email: employeeEmail,
                        mobile: employeeMobile,
                        address: employeeAddress,
                        password: employeePassword,
                        role: employeeRole ? "admin" : "user",
                        viewDashboard,
                        viewSystemUsers,
                        viewPurchase,
                        viewAccounts,
                        viewAccountsVerification,
                        viewDiscounts,
                        viewOrders,
                        viewOrdersApp,
                        viewOrderReturn,
                        viewMaster,
                        viewTransaction,
                        viewOrderStatus,
                        viewAccounting,
                        viewPrintAd,
                        viewBankList,
                        viewPaymentList,
                        viewPurchaseBarcode,
                        viewProductBarcode,
                        viewOrderPrintPage,
                        viewBrandsList,
                        viewCategoryList,
                        viewOfferList,
                        viewTrendingList,
                        viewBrochureList,
                        viewPriceList,
                        isAccountVerified: true,
                        isSystemUser: false,
                      };
                      // let copy = _.clone(employees);
                      // copy.push(data);
                      // setEmployees(data);
                      employees.push(data);
                      setEmployeeName("");
                      setEmployeeEmail("");
                      setEmployeePassword("");
                      setEmployeeMobile("");
                      setEmployeeAddress("");
                      setSelectAll(null);
                      setViewDashboard(null);
                      setViewSystemUsers(null);
                      setViewAccountUsers(null);
                      setViewPurchase(null);
                      setViewAccounts(null);
                      setViewAccountsVerification(null);
                      setViewDiscounts(null);
                      setViewOrders(null);
                      setViewOrdersApp(null);
                      setViewOrderReturn(null);
                      setViewMaster(null);
                      setViewTransaction(null);
                      setViewOrderStatus(null);
                      setViewAccounting(null);
                      setViewPrintAd(null);
                      setViewBankList(null);
                      setViewPaymentList(null);
                      setViewPurchaseBarcode(null);
                      setViewProductBarcode(null);
                      setViewOrderPrintPage(null);
                      setViewBrandsList(null);
                      setViewCategoryList(null);
                      setViewOfferList(null);
                      setViewTrendingList(null);
                      setViewBrochureList(null);
                      setViewPriceList(null);
                      setEmployeeRole(false);
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </GridItem>
                <GridItem xs={12}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {accountUsersColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {employees.length > 0 &&
                        employees.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {accountUsersColumns.map((column) => {
                                const value = row[column.id];
                                if (value && column.id === "leaderId") {
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.format &&
                                        typeof value === "number"
                                        ? column.format(value.name)
                                        : value.name}
                                    </TableCell>
                                  );
                                }

                                if (column.id === "permissions") {
                                  let {
                                    viewDashboard,
                                    viewSystemUsers,
                                    viewPurchase,
                                    viewAccounts,
                                    viewAccountsVerification,
                                    viewDiscounts,
                                    viewOrders,
                                    viewOrdersApp,
                                    viewOrderReturn,
                                    viewMaster,
                                    viewTransaction,
                                    viewOrderStatus,
                                    viewAccounting,
                                    viewPrintAd,
                                    viewBankList,
                                    viewPaymentList,
                                    viewPurchaseBarcode,
                                    viewProductBarcode,
                                    viewOrderPrintPage,
                                    viewBrandsList,
                                    viewCategoryList,
                                    viewOfferList,
                                    viewTrendingList,
                                    viewBrochureList,
                                    viewPriceList,
                                  } = row;
                                  return (
                                    <TableCell>
                                      <GridContainer>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Dashboard"
                                            control={
                                              <Checkbox
                                                checked={viewDashboard}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View System Users"
                                            control={
                                              <Checkbox
                                                checked={viewSystemUsers}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Purchase"
                                            control={
                                              <Checkbox
                                                checked={viewPurchase}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Accounts"
                                            control={
                                              <Checkbox
                                                checked={viewAccounts}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View AccountsVerification"
                                            control={
                                              <Checkbox
                                                checked={
                                                  viewAccountsVerification
                                                }
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Discounts"
                                            control={
                                              <Checkbox
                                                checked={viewDiscounts}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Orders"
                                            control={
                                              <Checkbox
                                                checked={viewOrders}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View OrdersApp"
                                            control={
                                              <Checkbox
                                                checked={viewOrdersApp}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Orders Return"
                                            control={
                                              <Checkbox
                                                checked={viewOrderReturn}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Master"
                                            control={
                                              <Checkbox
                                                checked={viewMaster}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Transaction"
                                            control={
                                              <Checkbox
                                                checked={viewTransaction}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View OrderStatus"
                                            control={
                                              <Checkbox
                                                checked={viewOrderStatus}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Accounting"
                                            control={
                                              <Checkbox
                                                checked={viewAccounting}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View PrintAd"
                                            control={
                                              <Checkbox
                                                checked={viewPrintAd}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View BankList"
                                            control={
                                              <Checkbox
                                                checked={viewBankList}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View PaymentList"
                                            control={
                                              <Checkbox
                                                checked={viewPaymentList}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Purchase Barcode"
                                            control={
                                              <Checkbox
                                                checked={viewPurchaseBarcode}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Product Barcode"
                                            control={
                                              <Checkbox
                                                checked={viewProductBarcode}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View Order Print Page "
                                            control={
                                              <Checkbox
                                                checked={viewOrderPrintPage}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View BrandsList"
                                            control={
                                              <Checkbox
                                                checked={viewBrandsList}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View CategoryList"
                                            control={
                                              <Checkbox
                                                checked={viewCategoryList}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View OfferList"
                                            control={
                                              <Checkbox
                                                checked={viewOfferList}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View TrendingList"
                                            control={
                                              <Checkbox
                                                checked={viewTrendingList}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View BrochureList"
                                            control={
                                              <Checkbox
                                                checked={viewBrochureList}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={3}>
                                          <FormControlLabel
                                            label="View PriceList"
                                            control={
                                              <Checkbox
                                                checked={viewPriceList}
                                                color="default"
                                              />
                                            }
                                          />
                                        </GridItem>
                                      </GridContainer>
                                    </TableCell>
                                  );
                                }

                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </GridItem>
              </GridContainer>
            </CardBody>
          ) : null}
          {activeStep === 2 ? (
            <CardBody>
              <GridContainer>
                <GridItem xs={3}>
                  <Autocomplete
                    id="product"
                    className={classes.formControl}
                    options={products}
                    value={product}
                    onChange={(event, value) => {
                      setProduct(value);
                    }}
                    getOptionLabel={(option) => option.productName}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Product" />
                    )}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <CustomInput
                    labelText="Price"
                    id="price"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: product ? product.price : "",
                    }}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <CustomInput
                    labelText="Discount Percent"
                    id="discountPercent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      value: discountPercent,
                      onChange: (event) => {
                        let discountPercent = event.target.value;
                        setDiscountPercent(discountPercent);
                        let price = product ? product.price : 0;
                        let discountPrice =
                          price - (discountPercent / 100) * price;
                        setDiscountedPrice(discountPrice);
                      },
                    }}
                  />
                </GridItem>

                <GridItem xs={2}>
                  <CustomInput
                    labelText="Discounted Price"
                    id="discountedPrice"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: discountedPrice,
                    }}
                  />
                </GridItem>
                <GridItem xs={1}>
                  <Fab
                    size={"small"}
                    color="primary"
                    aria-label="add"
                    onClick={() => {
                      if (product === "") {
                        return alert("Please select product");
                      }
                      if (discountPercent === "") {
                        return alert("Please enter discount percent");
                      }
                      if (discountedPrice === "") {
                        return alert("Please discount amount");
                      }
                      let data = {
                        product,
                        discountPercent,
                        discountedPrice,
                      };
                      let copy = _.clone(discounts);
                      copy.push(data);
                      setProduct(null);
                      setDiscountedPrice("");
                      setDiscountPercent("");
                      setDiscounts(copy);
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </GridItem>
                {_.isEmpty(discounts) ? null : (
                  <GridItem xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {discounts.map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];
                                if (column.id === "product") {
                                  value = value.productName;
                                }
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          ) : null}
        </Card>
        <GridContainer>
          <GridItem xs={12}>
            <Box
              display={"flex"}
              margin={4}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {activeStep === steps.length ? (
                <Button variant="contained" color="primary" onClick={_onSave}>
                  Edit Account
                </Button>
              ) : (
                <Box
                  flexDirection={"column"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {/*<Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>*/}
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </GridItem>
        </GridContainer>
      </div>
    </Dialog>
  );
}
