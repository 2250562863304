import React, { useEffect, useState } from "react";
// @material-components
import {
  Dialog,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  Checkbox,
  FormControlLabel,
  Box,
  IconButton,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";

import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Tabs from "../../components/CustomTabs/CustomTabs.js";
import _ from "lodash";
import moment from "moment";
import {
  GetAllUserAPI,
  GetAccountAPI,
  GetAllBanksAPI,
  GetAllBillsAPI,
  GetAllChallansAPI,
  GetAllOrdersAPI,
  GetAllPrintAdsAPI,
  GetBillAPI,
  GetEntityAPI,
} from "../../config/api";
import axios from "axios";
import Check from "@material-ui/icons/Check";
import styles from "../../../src/assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import { useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ChallanView from "../Challan/ChallanView.js";
import { toast } from "react-toastify";
import { Receipt } from "@material-ui/icons";
import { invoiceTemplate } from "../../assets/html/invoiceTemplate.js";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils/index.js";
import { challan1Template } from "../../assets/html/challan1Template.js";
import { challan2Template } from "../../assets/html/challan2Template.js";
import { challan3Template } from "../../assets/html/challan3Template.js";
let s = _.merge(styles, {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
});

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const accountUsersColumns = [
  { id: "name", label: "Name" },
  { id: "mobile", label: "Mobile" },
  { id: "leaderId", label: "Leader" },
  { id: "role", label: "Role" },
  { id: "permissions", label: "Permissions" },
];

const discountColumns = [
  { id: "product", label: "Product" },
  { id: "discountPercent", label: "Discount Percent" },
  { id: "discountedPrice", label: "Discounted Price" },
];

const challanColumns = [
  { id: "code", label: "Challan Id" },
  // {id: 'party.name', label: 'Party',},
  { id: "orderNo", label: "Order Id" },
  { id: "amount", label: "Challan Amount" },
  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "" },
];

const billColumns = [
  { id: "code", label: "Challan Id" },
  // {id: 'party.name', label: 'Party',},
  { id: "orderNo", label: "Order Id" },
  { id: "amount", label: "Bill Amount" },
  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "Action" }
];

const orderColumns = [
  { id: "code", label: "Order Id" },
  { id: "orderType", label: "Order Type" },
  // {id: 'product', label: 'Product',},
  { id: "orderAmount", label: "Order Amount" },
  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
];
export default function AccountView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item } = props;
  let authToken = useSelector((state) => state.userReducer.authToken);
  const userInfo = useSelector((state) => state.userReducer.userInfo);

  console.log("i tem", item)

  const {
    // _id,
    accountCode,
    partyType,
    name,
    contact,
    address,
    city,
    state,
    pin,
    landline,
    mobile,
    email,
    shippingAddress,
    thresholdBalance,
    whatsappNumber,
    openBalance,
    currentBalance,
    lastYearBalance,
    challanType,
    showRunningBalance,
    showPrice,
    cashPreference,
    runningChallanBalance,
    runningBillBalance,
    openingChallanBalance,
    openingBillBalance,
    gstNumber,
  } = item;

  useEffect(() => {
    _getOrders();
    _getChallans();
    _getBills();
    _getAccountUsers();
    _getEntity()
  }, [item]);

  const [orders, setOrders] = useState([]);
  const [discounts, setDiscounts] = useState(item.discounts);
  const [challans, setChallans] = useState([]);
  const [bills, setBills] = useState([]);
  const [accountUsers, setAccountUsers] = useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [entityDetails, setEntityDetails] = useState({})
  const [selectedChallan, setSelectedChallan] = useState(null)
  const [showInvoiceSelectionDialog, setShowInvoiceSelectionDialog] = useState(false)
  const [openingBalance, setOpeningBalance] = useState('')

  async function _getOrders() {
    try {
      let query = `&userId=${item._id}`;
      let res = await axios.get(GetAllOrdersAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setOrders(res.data.orders);
    } catch (err) {
      // console.log("error:", err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getBills() {
    try {
      let query = `&userId=${item._id}`;
      let res = await axios.get(GetAllBillsAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setBills(res.data.bills);
    } catch (err) {
      // console.log("error:", err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getChallans() {
    try {
      let query = `&userId=${item._id}`;
      let res = await axios.get(GetAllChallansAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setChallans(res.data.challans);

      const filteredChallans = res.data.challans.filter((ch) => ch.isBilled === false && ch.paymentStatus === 'Pending' && moment(ch.createdAt).isBefore(moment(), 'day'));

      const sumOfAmounts = filteredChallans.reduce((sum, ch) => Number(sum) + Number(ch.amount), 0);

      setOpeningBalance(sumOfAmounts)


    } catch (err) {
      // toast.error({ err }, {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _getAccountUsers() {
    try {
      let query = `&accountCode=${item?.accountCode}`;
      let res = await axios.get(GetAllUserAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setAccountUsers(res.data.users);
    } catch (err) {
      // toast.error(('Cannot fetch data'), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  async function _getEntity() {
    try {
      let res = await axios.get(GetEntityAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
      // console.log(res.data)
      setEntityDetails(res.data)
    }

    catch (e) {

    }
  }

  async function _onPreview(selectedItem) {
    console.log(selectedItem)
    if (_.isEmpty(selectedItem)) {
      return;
    }
    let htmlData = {},
      htmlTemplate = {};
    let { party } = selectedItem;
    let res = await axios.get(GetAllBanksAPI(userInfo.entity));

    let accountRes = await axios.get(GetAccountAPI(party.accountId), {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    let partyGstNumber = accountRes.data.gstNumber;
    let partyAddress = accountRes.data.city + "," + accountRes.data.state;
    console.log(selectedItem)
    htmlData = {
      clientAddress: partyAddress,
      isEligibleForIgst: party?.isEligibleForIgst,
      clientName: party.accountName,
      clientGSTNo: partyGstNumber,
      challanDate: moment().format("DD-MM-YYYY"),
      items: selectedItem.items,
      bank: res.data.banks[0],
      billCode: selectedItem.code,
      entityDetails,
      packagingCosts: selectedItem.packagingCosts,
      packagingTaxPercent: selectedItem.packagingTaxPercents
    };
    let options = {
      orientation: "p",
      unit: "pt",
      format: [850, 1066],
      // format:'letter',
      putOnlyUsedFonts: true,
    };
    htmlTemplate = invoiceTemplate(htmlData);
    await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
      const preBlob = dataURItoBlob(dataString);
      window.open(URL.createObjectURL(preBlob));
    });
  }

  const handleOpenInvoiceSelectionModal = (item) => {
    setSelectedChallan(item);
    setShowInvoiceSelectionDialog(true);
  };

  let invoiceTemplates = [
    {
      label: "Thermal",
      value: "challan1",
    },
    {
      label: "Challan",
      value: "challan2",
    },
    {
      label: "A6",
      value: "challan3",
    },
  ];

  async function _onPreviewChallan(invoiceType) {
    if (_.isEmpty(selectedChallan)) {
      return;
    }
    let htmlData = {},
      htmlTemplate = {};
    let { party } = selectedChallan;
    let challanCode = selectedChallan.code;
    let packagingCost = selectedChallan.packagingCost
    console.log(selectedChallan, "my item")
    let items = [],
      totalQuantity = 0;
    _.map(selectedChallan.items, async (item, index) => {
      let {
        codeL,
        codeR,
        nameL,
        nameR,
        rateR,
        rateL,
        quantity,
        amount,
        fitting,
        fittingAmount,
        discountAmount,
        discountPercent,
        tint,
      } = item;
      let productName = "",
        price = 0;

      items.push({
        name: productName,
        rate: amount,
        codeR,
        codeL,
        nameL,
        nameR,
        rateR,
        rateL,
        fitting,
        fittingAmount,
        quantity,
        discountAmount,
        discountPercent,
        tint,
      });
    });



    let res = await axios.get(GetAllPrintAdsAPI(userInfo.entity));
    let printAds = res.data.printAds;

    let ad = "";
    if (printAds && printAds[0]) {
      printAds.map((item) => {
        ad = ad + item.title + " ";
      });
    }
    let accountRes = await axios.get(GetAccountAPI(party.accountId), {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    htmlData = {
      ad,
      clientAddress: party?.accountAddress ? party.accountAddress : "",
      clientName: party.accountName,
      clientGSTNo: party.gst,
      challanDate: moment().format("DD-MM-YYYY"),
      items: selectedChallan.items,
      quantity: totalQuantity,
      challanCode,
      packagingCost: selectedChallan.packagingCost,
      entityName: entityDetails.name,
      runningChallanBalance: accountRes.data.showRunningBalance
        ? party.runningChallanBalance
        : null,
    };

    console.log(htmlData, "my data")
    let options = null;
    if (invoiceType === "challan1") {
      options = {
        orientation: "p",
        unit: "pt",
        format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };
      htmlTemplate = challan1Template(htmlData);
    } else if (invoiceType === "challan2") {
      options = {
        orientation: "p",
        unit: "pt",
        format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };
      htmlTemplate = challan2Template(htmlData);
    } else if (invoiceType === "challan3") {
      options = {
        orientation: "p",
        unit: "px",
        format: "a6",
        // format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };
      htmlTemplate = challan3Template(htmlData);
    }

    await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
      const preBlob = dataURItoBlob(dataString);
      window.open(URL.createObjectURL(preBlob));
    });
  }



  const InvoiceSelectionDialog = () => {
    return (
      <Dialog
        onClose={() => setShowInvoiceSelectionDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={showInvoiceSelectionDialog}
      >
        <DialogTitle id="simple-dialog-title">Select Invoice</DialogTitle>
        <List>
          {invoiceTemplates.map(({ label, value }) => (
            <ListItem
              button
              onClick={() => {
                _onPreviewChallan(value);
                setShowInvoiceSelectionDialog(false);
              }}
              key={value}
            >
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  };



  function _renderTab1() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="A/C Code"
            id="accountCode"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              disabled: true,
              value: accountCode ? accountCode : 918276,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Party Type"
            id="partyType"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: partyType,
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Name"
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: name,
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Contact"
            id="contact"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: contact,
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Email"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: email,
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Gst Number"
            id="gstNumber"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              value: gstNumber,
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Address"
            id="address"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              value: address,
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="City"
            id="city"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: city,
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="State"
            id="state"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: state,
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Pincode"
            id="pin"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: pin,
              disabled: true,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Landline"
            id="Landline"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: landline,
              disabled: true,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Whatsapp Number"
            id="whatsappNumber"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: whatsappNumber,
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Mobile"
            id="mobile"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: mobile,
              disabled: true,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Shipping Address"
            id="shippingAddress"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              value:
                shippingAddress.length > 0 ? shippingAddress[0].address : "",
              disabled: true,
            }}
          />
        </GridItem>
        
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Challan Opening Balance"
            id="challanBalance"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: _.round(openingChallanBalance, 2),
              disabled: true,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Challan Running Balance"
            id="challanBalance"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: _.round(runningChallanBalance, 2),
              disabled: true,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Bill Opening Balance"
            id="billBalance"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: _.round(openingBillBalance, 2),
              disabled: true,
            }}
          />
        </GridItem>
        
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Bill Running Balance"
            id="billBalance"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: _.round(runningBillBalance, 2),
              disabled: true,
            }}
          />
        </GridItem>
        
       
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Challan Type"
            id="lastYearBalance"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: challanType,
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={2} style={{ alignSelf: "end" }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled
                tabIndex={-1}
                checked={cashPreference}
                value={cashPreference}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label="Cash Preference"
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={2} style={{ alignSelf: "end" }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled
                tabIndex={-1}
                checked={showRunningBalance}
                value={showRunningBalance}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label="Show Running Balance"
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={2} style={{ alignSelf: "end" }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled
                tabIndex={-1}
                checked={showPrice}
                value={showPrice}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label="Show Pirce in Printables"
          />
        </GridItem>
      </GridContainer>
    );
  }

  function _renderTab2() {
    return (
      <GridItem xs={12}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {discountColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {discounts.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {discountColumns.map((column) => {
                    let value = row[column.id];
                    if (column.id === "product") {
                      value = row.product.productName;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </GridItem>
    );
  }

  function _renderTab3() {
    return (
      <GridItem xs={12}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {orderColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {orderColumns.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </GridItem>
    );
  }

  function _renderTab4() {
    return (
      <GridItem xs={12}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {challanColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {challans.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {challanColumns.map((column) => {
                    const value = row[column.id];

                    if (column.id === "orderNo") {
                      let orderString = "";
                      row.items.forEach((i, index) => {
                        if (index + 1 === row.items.length) {
                          orderString += i.orderRef;
                        } else {
                          orderString = orderString + i.orderRef + ", ";
                        }
                      });

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {orderString}
                        </TableCell>
                      );
                    }

                    if (column.id === "action") {
                      return (
                        <TableCell align={"center"}>
                          <Box display={"flex"} justifyContent={"center"}>
                            <IconButton
                              onClick={() => handleOpenInvoiceSelectionModal(row)}
                              aria-label="view"
                            >
                              <Receipt />
                            </IconButton>
                            <IconButton
                              aria-label="view"
                              onClick={() =>
                                setOpenViewDialog({
                                  open: true,
                                  item: row,
                                })
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      )
                    }

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {
          openViewDialog ? (
            <ChallanView
              open={openViewDialog.open}
              close={closeViewDialog}
              item={openViewDialog.item}
            />
          ) : null
        }
        <InvoiceSelectionDialog />
      </GridItem>

    );
  }

  function _renderTab5() {
    return (
      <GridItem xs={12}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {billColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bills.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {billColumns.map((column) => {
                    const value = row[column.id];

                    if (column.id === "orderNo") {
                      let orderString = "";
                      row.items.forEach((i, index) => {
                        if (i.orderRef) {
                          if (index + 1 === row.items.length) {
                            orderString += i.orderRef;
                          } else {
                            orderString = orderString + i.orderRef + ", ";
                          }
                        }
                      });


                      return (
                        <TableCell key={column.id} align={column.align}>
                          {orderString}
                        </TableCell>
                      );
                    }

                    if (column.id === "action") {
                      return (
                        <TableCell align={"center"}>
                          <Box display={"flex"} justifyContent={"center"}>
                            <IconButton
                              onClick={() => _onPreview(row)}
                              aria-label="view"
                            >
                              <Receipt />
                            </IconButton>
                            {/* <IconButton
                              aria-label="view"
                              onClick={() =>
                                setOpenViewDialog({
                                  open: true,
                                  item: row,
                                })
                              }
                            >
                              <VisibilityIcon />
                            </IconButton> */}
                          </Box>
                        </TableCell>
                      )
                    }

                    if (column.id === "amount") {
                      let totalAmount = 0;
                      row.items.forEach((i, index) => {
                        totalAmount += Number(i.amount);
                      });

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {totalAmount}
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </GridItem>
    );
  }

  function _renderTab6() {
    return (
      <GridItem xs={12}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {accountUsersColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {accountUsers.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {accountUsersColumns.map((column) => {
                    const value = row[column.id];
                    if (value && column.id === "leaderId") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value.name)
                            : value.name}
                        </TableCell>
                      );
                    }

                    if (column.id === "permissions") {
                      let {
                        viewDashboard,
                        viewSystemUsers,
                        viewPurchase,
                        viewAccounts,
                        viewAccountsVerification,
                        viewDiscounts,
                        viewOrders,
                        viewOrdersApp,
                        viewOrderReturn,
                        viewMaster,
                        viewTransaction,
                        viewOrderStatus,
                        viewAccounting,
                        viewPrintAd,
                        viewBankList,
                        viewPaymentList,
                        viewPurchaseBarcode,
                        viewProductBarcode,
                        viewOrderPrintPage,
                        viewBrandsList,
                        viewCategoryList,
                        viewOfferList,
                        viewTrendingList,
                        viewBrochureList,
                        viewPriceList,
                      } = row;
                      return (
                        <TableCell>
                          <GridContainer>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Dashboard"
                                control={
                                  <Checkbox
                                    checked={viewDashboard}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View System Users"
                                control={
                                  <Checkbox
                                    checked={viewSystemUsers}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Purchase"
                                control={
                                  <Checkbox
                                    checked={viewPurchase}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Accounts"
                                control={
                                  <Checkbox
                                    checked={viewAccounts}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View AccountsVerification"
                                control={
                                  <Checkbox
                                    checked={viewAccountsVerification}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Discounts"
                                control={
                                  <Checkbox
                                    checked={viewDiscounts}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Orders"
                                control={
                                  <Checkbox
                                    checked={viewOrders}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View OrdersApp"
                                control={
                                  <Checkbox
                                    checked={viewOrdersApp}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Orders Return"
                                control={
                                  <Checkbox
                                    checked={viewOrderReturn}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Master"
                                control={
                                  <Checkbox
                                    checked={viewMaster}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Transaction"
                                control={
                                  <Checkbox
                                    checked={viewTransaction}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View OrderStatus"
                                control={
                                  <Checkbox
                                    checked={viewOrderStatus}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Accounting"
                                control={
                                  <Checkbox
                                    checked={viewAccounting}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View PrintAd"
                                control={
                                  <Checkbox
                                    checked={viewPrintAd}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View BankList"
                                control={
                                  <Checkbox
                                    checked={viewBankList}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View PaymentList"
                                control={
                                  <Checkbox
                                    checked={viewPaymentList}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Purchase Barcode"
                                control={
                                  <Checkbox
                                    checked={viewPurchaseBarcode}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Product Barcode"
                                control={
                                  <Checkbox
                                    checked={viewProductBarcode}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View Order Print Page "
                                control={
                                  <Checkbox
                                    checked={viewOrderPrintPage}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View BrandsList"
                                control={
                                  <Checkbox
                                    checked={viewBrandsList}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View CategoryList"
                                control={
                                  <Checkbox
                                    checked={viewCategoryList}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View OfferList"
                                control={
                                  <Checkbox
                                    checked={viewOfferList}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View TrendingList"
                                control={
                                  <Checkbox
                                    checked={viewTrendingList}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View BrochureList"
                                control={
                                  <Checkbox
                                    checked={viewBrochureList}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={3}>
                              <FormControlLabel
                                label="View PriceList"
                                control={
                                  <Checkbox
                                    checked={viewPriceList}
                                    color="default"
                                  />
                                }
                              />
                            </GridItem>
                          </GridContainer>
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </GridItem>
    );
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <Tabs
        handleClose={close}
        title="Account Detail:"
        headerColor="rose"
        tabs={[
          {
            tabName: "Account Details",
            tabIcon: BugReport,
            tabContent: _renderTab1(),
          },
          {
            tabName: "Account Users",
            tabIcon: BugReport,
            tabContent: _renderTab6(),
          },
          {
            tabName: "Discounts",
            tabIcon: Code,
            tabContent: _renderTab2(),
          },
          {
            tabName: "Orders",
            tabIcon: Cloud,
            tabContent: _renderTab3(),
          },
          {
            tabName: "Challans",
            tabIcon: Cloud,
            tabContent: _renderTab4(),
          },
          {
            tabName: "Bills",
            tabIcon: Cloud,
            tabContent: _renderTab5(),
          },
        ]}
      />
    </Dialog>


  );
}
