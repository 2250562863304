import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Box,
  InputAdornment,
  Checkbox,
  Dialog,
  Fab,
  FormControlLabel,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import People from "@material-ui/icons/People";
import DateUtils from "@date-io/moment";

// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import _ from "lodash";
import axios from "axios";
import AddIcon from "@material-ui/icons/Add";
import { Autocomplete } from "@material-ui/lab";
import Check from "@material-ui/icons/Check";
import styles from "../../../src/assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  CreateProductMasterAPI,
  CreatePurchaseAPI,
  CreateReadyMasterAPI,
  GetAllAccountsAPI,
  GetAllProductMastersAPI,
  GetAllPurchasesAPI,
  GetAllReadyMastersAPI,
  GetBulkReadyMasterRangeAPI,
  GetReadyMasterRangeProducts,
  UpdateProductMasterAPI,
  UpdateReadyMasterAPI,
} from "../../config/api";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";

import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

let s = _.merge(styles, {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
});

const useStyles = makeStyles(s);

const readyMasterRangeProductColumn = [
  { id: "index", label: "Sr No." },
  { id: "productCode", label: "Product Code" },

  { id: "sph", label: "Sph" },
  { id: "cyl", label: "Cyl" },
  { id: "axis", label: "Axis" },
  { id: "addition", label: "Addition" },
  { id: "threshold", label: "Threshold" },
  { id: "quantity", label: "Existing Quantity" },
  { id: "newQuantity", label: "New Quantity" },
  { id: "totalQuantity", label: "Total Quantity" },
];

const productColumns = [
  { id: "productCode", label: "Product Code" },
  { id: "productName", label: "Product Name" },
  { id: "addedQuantity", label: "Quantity" },
  { id: "rate", label: "Purchase Rate" },
  { id: "total", label: "Total" },
  { id: "action", label: "Action" },
];
const products = [
  "Product 1",
  "Product 2",
  "Product 3",
  "Product 4",
  "Product 5",
];

const gstPer = [
  { label: "12% GST", value: 12 },
  { label: "18% GST", value: 18 },
];

const sides = ["B", "L", "R"];

export default function PurchaseAdd(props) {
  const classes = useStyles();
  const theme = useTheme();
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  const authToken = useSelector((state) => state.userReducer.authToken);

  const { open, close, type, fetchData } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  // const readyMasters = useSelector((state) => state.userReducer.readyMasters);

  // console.log(ready)

  const [products, setProducts] = useState([]);
  const [challan, setChallan] = useState(null);
  const [bill, setBill] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [product, setProduct] = useState("");
  const [party, setParty] = useState(null);

  const [color, setColor] = useState("");
  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [rate, setRate] = useState("");
  const [saleRate, setSaleRate] = useState("");
  const [index, setIndex] = useState("");
  const [side, setSide] = useState("");
  const [types, setTypes] = useState("");
  const [threshold, setThreshold] = useState("");
  const [sph, setSph] = useState("0");
  const [cyl, setCyl] = useState("0");
  const [axis, setAxis] = useState("0");
  const [addition, setAddition] = useState("0");
  const [hsnCode, setHsnCode] = useState("");
  const [taxPercent, setTaxPercent] = useState("");
  const [loading, setLoading] = useState(false);

  const [existingQuantity, setExistingQuantity] = useState("");
  const [newQuantity, setNewQuantity] = useState("");
  const [totalQuantity, setTotalQuantity] = useState("");
  const [total, setTotal] = useState("");
  const [netAmount, setNetAmount] = useState("");
  const [totalBillAmount, setTotalBillAmount] = useState("");
  const [selectedGSTPer, setSelectedGSTPer] = useState("");
  const [purchaseType, setPurchaseType] = useState("Challan");
  const [readyMasterRange, setReadyMasterRange] = useState([]);
  const [selectedReadyMasterRange, setSelectedReadyMasterRange] =
    useState(null);
  const [readyMasterRangeProducts, setReadyMasterRangeProduct] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [packagingAmount, setPackagingAmount] = useState("");
  const [purchaseDate, setPurchaseDate] = useState(null);

  const [saveButtonStatus, setSaveButtonStatus] = useState(false);

  console.log(loading, "Loading");

  useEffect(() => {
    _getAccounts();
    _getBulkReadyMasterRangeAPI();
  }, [open]);

  useEffect(() => {
    if (selectedReadyMasterRange != null) {
      _getBulkReadyMasterRangeProductsAPI();
    } else {
      setLoading(false);
    }
  }, [selectedReadyMasterRange]);

  useEffect(() => {
    let netAmountTemp = 0;
    readyMasterRangeProducts.map((i) => {
      if (i.newQuantity > 0) {
        netAmountTemp += Number(i.newQuantity) * Number(i.rate);
      }
    });
    let totalBillAmount = netAmountTemp + Number(packagingAmount);
    setNetAmount(totalBillAmount);
    if (taxPercent && purchaseType === "Bill") {
      totalBillAmount =
        netAmountTemp +
        Number(packagingAmount) +
        netAmountTemp * (Number(taxPercent) / 100);
    }
    setTotalBillAmount(totalBillAmount);
  }, [readyMasterRangeProducts]);

  useEffect(() => {
    setProducts([]);
    setChallan(null);
    setBill(null);
    setSelectedProducts([]);
    setProduct("");
    setParty(null);
    setColor("");
    setProductName("");
    setProductCode("");
    setRate("");
    setIndex("");
    setSide("");
    setTypes("");
    setThreshold("");
    setSph("0");
    setCyl("0");
    setAxis("0");
    setAddition("0");
    setHsnCode("");
    setTaxPercent("");
    setExistingQuantity("");
    setNewQuantity("");
    setTotalQuantity("");
    setTotal("");
    setNetAmount("");
    setTotalBillAmount("");
    setSelectedGSTPer("");
    setSelectedReadyMasterRange(null);
    setSearchTerm("");
    setPackagingAmount("");
    setPurchaseDate(null);
  }, [purchaseType]);

  const manageDataPunching = (row, event, parameter) => {
    let copy = _.clone(readyMasterRangeProducts);

    let rowIndex = _.findIndex(readyMasterRangeProducts, row);

    if (event.target.value !== "") {
      copy[rowIndex][parameter] =
        parseInt(copy[rowIndex]["quantity"]) + parseInt(event.target.value);
      copy[rowIndex]["newQuantity"] = parseInt(event.target.value);

      setReadyMasterRangeProduct(copy);
    } else {
      copy[rowIndex][parameter] = parseInt(copy[rowIndex]["quantity"]);
      copy[rowIndex]["newQuantity"] = 0;

      setReadyMasterRangeProduct(copy);
    }
  };

  const debouncingProducts = _.debounce(async (value) => {
    console.log("Debounce Ran 1", userInfo.entity);
    try {
      console.log("Debounce Ran 2", value);
      let query = `&productCode=${value}`;
      let res = await axios.get(
        GetAllReadyMastersAPI(userInfo.entity) + query,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Debounce Ran 3", res.data.readyMasters);
      setProducts(res.data.readyMasters);
    } catch (err) {
      console.log(err, "Ran error");
    }
  });

  // async function _getProducts() {
  //   try {
  //     let res = await axios.get(GetAllReadyMastersAPI(userInfo.entity), {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });
  //     setProducts(res.data.readyMasters);
  //   } catch (err) {
  //     // console.log(err);
  //   }
  // }

  async function _getAccounts() {
    try {
      let res = await axios.get(
        GetAllAccountsAPI(userInfo.entity),

        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let selectedAccounts = _.filter(res.data.accounts, {
        partyType: "purchase",
      });
      setAccounts(selectedAccounts);
    } catch (err) {
      // console.log(err);
    }
  }

  async function _onSave() {
    try {
      if (type === "Single Product" && _.isEmpty(selectedProducts)) {
        return alert("Please add products");
      }
      let fData = null;
      if (type === "Range Product") {
        await Promise.all(
          readyMasterRangeProducts.map((i) => {
            i.newQuantity = i.newQuantity;
            i.quantity = i.totalQuantity;
            i.total = Number(i.totalQuantity) * Number(i.rate);
          })
        );

        fData = readyMasterRangeProducts.filter((tc) => tc.quantity > 0);
      }

      if (party === "") {
        toast.error("Please Select a party");
        return;
      }

      let purchase = {
        purchaseType,
        type,
        challanNo: challan,
        billNo: bill,
        products: type === "Single Product" ? selectedProducts : fData,
        readyMasterRange:
          type === "Range Product" ? selectedReadyMasterRange : null,
        party,
        gst: bill ? taxPercent : 0,
        gstAmount: bill ? Number(totalBillAmount) - Number(netAmount) : 0,
        netAmount,
        totalAmount: totalBillAmount,
        entity: userInfo.entity,
        hsnCode: hsnCode,
        taxPercent: taxPercent,
        purchaseDate: purchaseDate ? moment.utc(purchaseDate) : moment.utc(),
        packagingAmount,
      };
      // console.log(purchase, "My purchase");
      // console.log(selectedProducts, "My selectedProducts");
      // console.log(fData, "My fData");
      // return

      setSaveButtonStatus(true);

      let res = await axios.post(CreatePurchaseAPI(), purchase, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      // if (!_.isEmpty(selectedProducts)) {
      //   await Promise.all(
      //     selectedProducts.map(async (i) => {
      //       console.log(i);
      //       if (!_.isEmpty(i._id)) {
      //         res = await axios.patch(UpdateReadyMasterAPI(i._id), i, {
      //           headers: {
      //             Authorization: `Bearer ${authToken}`,
      //           },
      //         });
      //       }
      //     })
      //   );
      // }

      // if (!_.isEmpty(fData)) {
      //   await Promise.all(
      //     fData.map(async (i) => {
      //       if (!_.isEmpty(i._id)) {
      //         res = await axios.patch(UpdateReadyMasterAPI(i._id), i, {
      //           headers: {
      //             Authorization: `Bearer ${authToken}`,
      //           },
      //         });
      //       }
      //     })
      //   );
      // }
      close();
      setSaveButtonStatus(false);
      fetchData();
    } catch (e) {
      setSaveButtonStatus(false);
      console.log("err:", e);
    }
    setSelectedProducts([]);
  }

  async function _getBulkReadyMasterRangeAPI() {
    try {
      let res = await axios.get(GetBulkReadyMasterRangeAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setReadyMasterRange(res.data.readyMasterRange);
    } catch (err) {
      // console.log(err);
    }
  }

  async function _getBulkReadyMasterRangeProductsAPI() {
    try {
      setLoading(true);
      let res = await axios.get(
        GetReadyMasterRangeProducts(
          userInfo.entity,
          selectedReadyMasterRange.startSph,
          selectedReadyMasterRange.endSph,
          selectedReadyMasterRange.startCyl,
          selectedReadyMasterRange.endCyl,
          selectedReadyMasterRange.startAddition,
          selectedReadyMasterRange.endAddition,
          selectedReadyMasterRange.axis,
          selectedReadyMasterRange.color,
          selectedReadyMasterRange.productName
        ),
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      res.data.readyMasterRangeProducts.map((i) => {
        i.totalQuantity = "";
      });
      setReadyMasterRangeProduct(res.data.readyMasterRangeProducts);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  let data = readyMasterRangeProducts;

  if (searchTerm !== "" && readyMasterRangeProducts.length > 0) {
    data = _.filter(data, (i) => {
      return (
        i.productCode &&
        i.productCode.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  console.log(moment(purchaseDate?._d), "my type");

  return (
    <Dialog
      fullWidth
      fullScreen
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Add Purchase</h4>
                <h4 className={classes.cardTitleWhite}>O.B Ch : {party?.openingChallanBalance}</h4>
                <h4 className={classes.cardTitleWhite}>O.B Bill : {party?.openingBillBalance}</h4>
                <h4 className={classes.cardTitleWhite}>R.B Ch : {party?.runningChallanBalance}</h4>
                <h4 className={classes.cardTitleWhite}>R.B Bill : {party?.runningBillBalance}</h4>
                <Button disabled={saveButtonStatus} color={"transparent"} onClick={() => _onSave()}>
                  Save
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={3} md={3} sm={3}>
                  <Autocomplete
                    id="purchaseType"
                    className={classes.formControl}
                    options={["Challan", "Bill"]}
                    value={purchaseType}
                    onChange={(event, value) => {
                      setPurchaseType(value);
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Purchase Type" />
                    )}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <MuiPickersUtilsProvider utils={DateUtils}>
                    <DatePicker
                      label="Purchase Date"
                      fullWidth
                      value={purchaseDate}
                      onChange={(date) => setPurchaseDate(date)}
                      animateYearScrolling
                      // style={{marginTop:5}}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  {purchaseType === "Challan" ? (
                    <CustomInput
                      labelText="Challan No."
                      id="challan"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: challan,
                        onChange: (event) => setChallan(event.target.value),
                      }}
                    />
                  ) : (
                    <CustomInput
                      labelText="Bill No"
                      id="bill"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: bill,
                        onChange: (event) => setBill(event.target.value),
                      }}
                    />
                  )}
                </GridItem>
                <GridItem xs={3} md={3} sm={3}>
                  <Autocomplete
                    id="product"
                    className={classes.formControl}
                    options={accounts}
                    value={party}
                    onChange={(event, value) => {
                      setParty(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Party" />
                    )}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Gross Amount"
                    id="netAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: netAmount,
                      // onChange: (event) => {
                      //   setNetAmount(event.target.value);
                      //   let totalBillAmount = Number(event.target.value) + Number(packagingAmount)
                      //   if (taxPercent && purchaseType === "Bill") {
                      //     totalBillAmount =
                      //       Number(event.target.value) +
                      //       Number(event.target.value)
                      //   }
                      //   setTotalBillAmount(totalBillAmount);
                      // },
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Packaging Amount"
                    id="packagingAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: packagingAmount,
                      onChange: (event) => {
                        setPackagingAmount(event.target.value);
                        let totalBillAmount = 0;
                        if (purchaseType === "Bill") {
                          totalBillAmount =
                            Number(event.target.value) +
                            Number(netAmount) +
                            (Number(event.target.value) + Number(netAmount)) *
                              (Number(taxPercent) / 100);
                        }
                        if (purchaseType === "Challan") {
                          totalBillAmount =
                            Number(event.target.value) + Number(netAmount);
                        }
                        setTotalBillAmount(totalBillAmount);
                      },
                    }}
                  />
                </GridItem>

                {/* {purchaseType === "Bill" ? (
                  <GridItem xs={4} sm={4} md={4}>
                    <Autocomplete
                      id="gst"
                      className={classes.formControl}
                      options={gstPer}
                      value={selectedGSTPer}
                      onChange={(event, value) => {
                        setSelectedGSTPer(value);
                        let totalBillAmount = Number(netAmount);
                        if (value) {
                          totalBillAmount =
                            Number(netAmount) +
                            Number(netAmount) * (Number(value.value) / 100);
                        }
                        setNetAmount(netAmount);
                        setTotalBillAmount(totalBillAmount);
                      }}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField {...params} label="Select GST%" />
                      )}
                    />
                  </GridItem>
                ) : null} */}
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText={
                      purchaseType === "Bill"
                        ? "Total Bill Amount (gross + packaging + tax)"
                        : "Total Bill Amount"
                    }
                    id="totalBillAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: purchaseType === "Bill" ? `${totalBillAmount} ( ${netAmount} + ${(Number(packagingAmount) + Number(packagingAmount * (taxPercent/100)) )} + ${Number(netAmount*(taxPercent/100))} )` : totalBillAmount ,
                      disabled: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              {type === "Single Product" && (
                <GridContainer>
                  <GridItem xs={3} md={3} sm={3}>
                    <Autocomplete
                      id="product"
                      filterOptions={filterOptions}
                      loading={products.length === 0 ? true : false}
                      className={classes.formControl}
                      options={products}
                      value={product}
                      onChange={(event, value) => {
                        setProduct(value);
                        if (value) {
                          console.log(value, "My Value after selecting");
                          setColor(value.color);
                          setProductName(value.productName);
                          setProductCode(value.productCode);
                          if (value.rate) {
                            setRate(value.rate);
                            setSaleRate(value.rate);
                          } else {
                            setRate(0);
                            setSaleRate(0);
                          }
                          setIndex(value.index);
                          setSide(value.side);
                          setTypes(value.type);
                          setSph(value.sph);
                          setCyl(value.cyl);
                          setAxis(value.axis);
                          setAddition(value.addition);
                          setThreshold(value.threshold);
                          setHsnCode(value.hsnCode);
                          setTaxPercent(value.taxPercent);
                          if (value.quantity) {
                            setExistingQuantity(value.quantity);
                            setNewQuantity(0);
                            setTotalQuantity(value.quantity);
                          } else {
                            setExistingQuantity(0);
                            setNewQuantity(0);
                            setTotalQuantity(0);
                          }
                          setTotal(Number(value.quantity) * Number(value.rate));
                        } else {
                          setColor("");
                          setProductName("");
                          setProductCode("");
                          setRate("");
                          setSaleRate("");
                          setIndex("");
                          setSide("");
                          setTypes("");
                          setSph("0");
                          setCyl("0");
                          setAxis("0");
                          setAddition("0");
                          setThreshold("");
                          setExistingQuantity("");
                          setNewQuantity("");
                          setTotalQuantity("");
                        }
                      }}
                      getOptionLabel={(option) =>
                        option.productName
                          ? `${option.productName} (${option.productCode})`
                          : null
                      }
                      onInputChange={(e, value) => {
                        if (value.length > 5) {
                          debouncingProducts(value);
                        }
                        return;
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Product" />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={3} md={3} sm={3}>
                    <CustomInput
                      labelText="color"
                      id="color"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: color,
                        onChange: (event) => setColor(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3} md={3} sm={3}>
                    <CustomInput
                      labelText="Product Name"
                      id="productName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: product ? true : false,
                        value: productName,
                        onChange: (event) => setProductName(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                    <CustomInput
                      labelText="Sph"
                      id="sph"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: sph,
                        onChange: (event) => {
                          let sph = event.target.value;
                          setSph(sph);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                    <CustomInput
                      labelText="Cyl"
                      id="cyl"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: cyl,
                        onChange: (event) => setCyl(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                    <CustomInput
                      labelText="Axis"
                      id="axis"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: axis,
                        onChange: (event) => setAxis(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                    <CustomInput
                      labelText="Addition"
                      id="addition"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: addition,
                        onChange: (event) => setAddition(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <CustomInput
                      labelText="Index"
                      id="index"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: index,
                        onChange: (event) => setIndex(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3} md={3} sm={3}>
                    {/* <CustomInput
                      labelText="side"
                      id="side"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: side,
                        onChange: (event) => setSide(event.target.value),
                      }}
                    /> */}
                    <Autocomplete
                      id="side"
                      loading={products.length === 0 ? true : false}
                      className={classes.formControl}
                      options={["B", "R", "L"]}
                      value={side}
                      onChange={(e) => setSide(e.target.value)}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Side" />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <CustomInput
                      labelText="Hsn Code"
                      id="hsnCode"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: hsnCode,
                        onChange: (event) => setHsnCode(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <CustomInput
                      labelText="Tax percent"
                      id="taxPercent"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: taxPercent,
                        onChange: (event) => setTaxPercent(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <CustomInput
                      labelText="Threshold"
                      id="threshold"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: threshold,
                        onChange: (event) => setThreshold(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={1} md={1} sm={1}>
                    <CustomInput
                      labelText="type"
                      id="type"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: types,
                        onChange: (event) => setTypes(event.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={2} sm={2} md={2}>
                    <CustomInput
                      labelText="Sale rate"
                      id="saleRate"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        value: saleRate,
                        //   onChange: (event) => setPrice(event.target.value),
                        // onChange: (event) => {
                        //   let n = event.target.value;
                        //   setRate(n);
                        //   let p = Number(newQuantity) * Number(n);
                        //   setTotal(p);
                        // },
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  
                  <GridItem xs={1} sm={1} md={1}>
                    <CustomInput
                      labelText="rate"
                      id="rate"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        value: rate,
                        //   onChange: (event) => setPrice(event.target.value),
                        onChange: (event) => {
                          let n = event.target.value;
                          setRate(n);
                          let p = Number(newQuantity) * Number(n);
                          setTotal(p);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2}>
                    <CustomInput
                      labelText="Existing Qty"
                      id="existingQuantity"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: true,
                        type: "number",
                        value: existingQuantity,
                        onChange: (event) =>
                          setExistingQuantity(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2}>
                    <CustomInput
                      labelText="New Qty"
                      id="newQuantity"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        value: newQuantity,
                        onChange: (event) => {
                          let n = event.target.value;
                          setNewQuantity(n);
                          setTotalQuantity(
                            Number(n) + Number(existingQuantity)
                          );
                          let p = Number(n) * Number(rate);
                          setTotal(p);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2}>
                    <CustomInput
                      labelText="Total Qty"
                      id="totalQuantity"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        value: totalQuantity,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                    <CustomInput
                      labelText="Total"
                      id="total"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        disabled: true,
                        value: total,
                        onChange: (event) => setTotal(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                    <Box justifyContent={"center"} marginTop={4}>
                      {productCode === "" ? null : (
                        <Fab
                          size={"small"}
                          color="primary"
                          aria-label="add"
                          onClick={() => {
                            let object = {
                              productName,
                              productCode,
                              rate,
                              index,
                              side,
                              type: types,
                              threshold,
                              color,
                              sph,
                              cyl,
                              axis,
                              addition,
                              existingQuantity: existingQuantity,
                              addedQuantity: newQuantity,
                              quantity: totalQuantity,
                              total,
                              _id: product ? product._id : null,
                            };
                            let copy = _.clone(selectedProducts);
                            copy.push(object);
                            setSelectedProducts(copy);
                            let netAmountTemp = Number(netAmount);
                            // _.map(copy, (i) => {
                            netAmountTemp += Number(newQuantity) * Number(rate);
                            // });
                            let totalBillAmount =
                              Number(netAmountTemp) + Number(packagingAmount);
                            setNetAmount(netAmountTemp);
                            console.log(purchaseType, "MY purchase type");
                            if (purchaseType === "Bill") {
                              console.log("calculating");
                              totalBillAmount =
                                totalBillAmount +
                                (totalBillAmount * Number(taxPercent)) / 100;
                            }
                            setTotalBillAmount(totalBillAmount);

                            setColor("");
                            setProduct(null);
                            setProductName("");
                            setProductCode("");
                            setRate("");
                            setIndex("");
                            setSide("");
                            setTypes("");
                            setSph("0");
                            setCyl("0");
                            setAxis("0");
                            setAddition("0");
                            setThreshold("");
                            setExistingQuantity("");
                            setNewQuantity("");
                            setTotalQuantity("");
                            setTotal("");
                            // setHsnCode("")
                            // setTaxPercent("")
                            // setBill("");
                            // setChallan("");
                          }}
                        >
                          <AddIcon />
                        </Fab>
                      )}
                    </Box>
                  </GridItem>
                  {_.isEmpty(selectedProducts) ? null : (
                    <GridItem xs={12}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {productColumns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedProducts.map((row, index) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.code}
                              >
                                {productColumns.map((column) => {
                                  const value = row[column.id];
                                  if (column.id === "action") {
                                    return (
                                      <TableCell align={"center"}>
                                        <Box
                                          display={"flex"}
                                          justifyContent={"center"}
                                        >
                                          <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                              let copy =
                                                _.clone(selectedProducts);
                                              let deletedProduct = copy[index];

                                              let deletedProductQuantity =
                                                deletedProduct.addedQuantity;
                                              let deletedProductPrice =
                                                deletedProduct.rate;

                                              console.log(
                                                "deletedProduct",
                                                deletedProduct
                                              );
                                              console.log(
                                                "deletedProductQuantity",
                                                deletedProductQuantity
                                              );
                                              console.log(
                                                "deletedProductPrice",
                                                deletedProductPrice
                                              );

                                              let deletedProductTotalAmount =
                                                Number(deletedProductQuantity) *
                                                Number(deletedProductPrice);
                                              let netAmountTemp =
                                                Number(netAmount);

                                              console.log(
                                                "netAmountTemp",
                                                netAmountTemp
                                              );
                                              console.log(
                                                "deletedProductTotalAmount",
                                                deletedProductTotalAmount
                                              );

                                              if (
                                                Number(netAmountTemp) -
                                                  Number(
                                                    deletedProductTotalAmount
                                                  ) <=
                                                0
                                              ) {
                                                netAmountTemp = 0;
                                              } else {
                                                netAmountTemp -=
                                                  deletedProductTotalAmount;
                                              }

                                              let totalBillAmount =
                                                Number(netAmountTemp) +
                                                Number(packagingAmount);

                                              setNetAmount(netAmountTemp);
                                              if (
                                                taxPercent &&
                                                purchaseType === "Bill"
                                              ) {
                                                totalBillAmount =
                                                  netAmountTemp +
                                                  netAmountTemp *
                                                    (Number(taxPercent) / 100);
                                              }
                                              setTotalBillAmount(
                                                totalBillAmount
                                              );

                                              copy.splice(index, 1);
                                              setSelectedProducts(copy);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Box>
                                      </TableCell>
                                    );
                                  }
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </GridItem>
                  )}
                </GridContainer>
              )}

              {type === "Range Product" && (
                <GridContainer>
                  <GridItem xs={3} md={3} sm={3}>
                    <Autocomplete
                      id="product"
                      loading={readyMasterRange.length === 0 ? true : false}
                      className={classes.formControl}
                      options={readyMasterRange}
                      value={selectedReadyMasterRange}
                      onChange={async (event, value) => {
                        // setLoading(true);
                        setSelectedReadyMasterRange(value);
                        if (value) {
                          setColor(value.color);
                          setProductName(value.productName);
                          // setProductCode(value.productCode);
                          if (value.rate) {
                            setRate(value.rate);
                          } else {
                            setRate(0);
                          }
                          setIndex(value.index);
                          setSide(value.side);
                          setTypes(value.type);
                          // setSph(value.sph);
                          // setCyl(value.cyl);
                          // setAxis(value.axis);
                          // setAddition(value.addition);
                          // setThreshold(value.threshold);
                          // if (value.quantity) {
                          //   setExistingQuantity(value.quantity);
                          //   setNewQuantity(0);
                          //   setTotalQuantity(value.quantity);
                          // } else {
                          //   setExistingQuantity(0);
                          //   setNewQuantity(0);
                          //   setTotalQuantity(0);
                          // }
                          // setTotal(Number(value.quantity) * Number(value.rate));
                        } else {
                          setColor("");
                          setProductName("");
                          setProductCode("");
                          setRate("");
                          setIndex("");
                          setSide("");
                          setTypes("");
                          setSph("0");
                          setCyl("0");
                          setAxis("0");
                          setAddition("0");
                          setThreshold("");
                          setExistingQuantity("");
                          setNewQuantity("");
                          setTotalQuantity("");
                        }
                      }}
                      getOptionLabel={(option) =>
                        option.productName ? `${option.productName}` : null
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Select Product" />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={3} md={3} sm={3}>
                    <CustomInput
                      labelText="color"
                      id="color"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: color,
                        onChange: (event) => setColor(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3} md={3} sm={3}>
                    <CustomInput
                      labelText="Product Name"
                      id="productName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: product ? true : false,
                        value: productName,
                        onChange: (event) => setProductName(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <CustomInput
                      labelText="Index"
                      id="index"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: index,
                        onChange: (event) => setIndex(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3} md={3} sm={3}>
                    {/* <CustomInput
                      labelText="side"
                      id="side"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: side,
                        onChange: (event) => setSide(event.target.value),
                      }}
                    /> */}
                    <Autocomplete
                      id="side"
                      loading={products.length === 0 ? true : false}
                      className={classes.formControl}
                      options={["B", "R", "L"]}
                      value={side}
                      onChange={(e) => setSide(e.target.value)}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Side" />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={3} md={3} sm={3}>
                    <CustomInput
                      labelText="type"
                      id="type"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: types,
                        onChange: (event) => setTypes(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                    <CustomInput
                      labelText="saleRate"
                      id="saleRate"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        value: saleRate,
                        //   onChange: (event) => setPrice(event.target.value),
                        // onChange: (event) => {
                        //   let n = event.target.value;
                        //   setRate(n);
                        //   let p = Number(newQuantity) * Number(n);
                        //   setTotal(p);
                        // },
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  
                  <GridItem xs={3} sm={3} md={3}>
                    <CustomInput
                      labelText="rate"
                      id="rate"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        value: rate,
                        //   onChange: (event) => setPrice(event.target.value),
                        onChange: (event) => {
                          let n = event.target.value;
                          setRate(n);
                          let p = Number(newQuantity) * Number(n);
                          setTotal(p);
                        },
                      }}
                    />
                  </GridItem>
                  {loading === true ? (
                    <GridItem
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        textAlign: "center",
                      }}
                    >
                      <h1>Loading...</h1>
                    </GridItem>
                  ) : (
                    <GridItem xs={12}>
                      <CustomInput
                        id="search"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          style: {
                            marginTop: 0,
                            marginRight: 10,
                          },
                          placeholder: "Search",
                          value: searchTerm,
                          onChange: (e) => setSearchTerm(e.target.value),
                          endAdornment: (
                            <InputAdornment position="end">
                              <People />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {readyMasterRangeProductColumn.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <GridItem
                              xs={12}
                              md={12}
                              style={{
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <h1>Loading...</h1>
                            </GridItem>
                          ) : (
                            data.map((row, index) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.code}
                                >
                                  {readyMasterRangeProductColumn.map(
                                    (column) => {
                                      const value = row[column.id];
                                      if (column.id === "action") {
                                        return (
                                          <TableCell align={"center"}>
                                            <Box
                                              display={"flex"}
                                              justifyContent={"center"}
                                            >
                                              <IconButton
                                                aria-label="delete"
                                                onClick={() => {
                                                  let copy =
                                                    _.clone(selectedProducts);
                                                  let deletedProduct =
                                                    copy[index];
                                                  let deletedProductQuantity =
                                                    deletedProduct.quantity;
                                                  let deletedProductPrice =
                                                    deletedProduct.rate;
                                                  let deletedProductTotalAmount =
                                                    Number(
                                                      deletedProductQuantity
                                                    ) *
                                                    Number(deletedProductPrice);
                                                  let netAmountTemp =
                                                    Number(netAmount);

                                                  netAmountTemp -=
                                                    deletedProductTotalAmount;

                                                  let totalBillAmount =
                                                    netAmountTemp;
                                                  setNetAmount(totalBillAmount);
                                                  if (
                                                    taxPercent &&
                                                    purchaseType === "Bill"
                                                  ) {
                                                    totalBillAmount =
                                                      netAmountTemp +
                                                      netAmountTemp *
                                                        (Number(taxPercent) /
                                                          100);
                                                  }
                                                  setTotalBillAmount(
                                                    totalBillAmount
                                                  );
                                                  copy.splice(index, 1);
                                                  setSelectedProducts(copy);
                                                }}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </Box>
                                          </TableCell>
                                        );
                                      }

                                      if (column.id === "newQuantity") {
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            <CustomInput
                                              labelText="New Qty"
                                              id="newQuantity"
                                              formControlProps={{
                                                fullWidth: true,
                                              }}
                                              inputProps={{
                                                value:
                                                  data[index]["newQuantity"],
                                                onChange: (event) =>
                                                  manageDataPunching(
                                                    row,
                                                    event,
                                                    "totalQuantity"
                                                  ),
                                              }}
                                            />
                                          </TableCell>
                                        );
                                      }

                                      if (column.id === "index") {
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {index + 1}
                                          </TableCell>
                                        );
                                      }
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          {value}
                                        </TableCell>
                                      );
                                    }
                                  )}
                                </TableRow>
                              );
                            })
                          )}
                        </TableBody>
                      </Table>
                    </GridItem>
                  )}
                </GridContainer>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
